import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { XseriesHeaderProps } from "./Xseries.d"
import { Image } from "../../atoms/Image"
import { LocationContext } from "../../../contexts/Location"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { ButtonLink } from "../../atoms/Button"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const XSeriesHeader: React.FC<XseriesHeaderProps> = ({
  title,
  headingText,
  subHeadingText,
  headerBackground,
  headerLogo,
  ctaHero,
  headerGallery,
}) => {
  const [{ city, dealers }] = useContext(LocationContext)
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <section
      aria-label="XSeries Hero Section"
      css={[
        tw`grid relative bg-gray-900`,
        tw`md:(items-center overflow-hidden)`,
      ]}
    >
      {headerBackground && (
        <Image
          imageData={headerBackground}
          css={[
            tw`absolute contrast-150 h-[350px] top-0 object-cover`,
            tw`sm:(h-[550px])`,
            "z-index: 0;",
            tw`md:(h-[690px] mt-0 w-full)`,
            tw`lg:(h-[890px])`,
          ]}
        />
      )}
      {/* BACKGROUND GRADIENT OVERLAY */}
      <div
        css={[
          tw`absolute w-full h-[550px] brightness-100 md:(h-full)`,
          tw`lg:(h-full)`,
          `z-index: 1;
  background: linear-gradient(
    to top, 
    rgba(37, 37, 37, 0) 52%, 
    rgba(244, 128, 127, 0) 68%, 
    rgba(50, 0, 0, 0) 0%),
    linear-gradient(
      to top, 
      rgba(37, 37, 37, 1) 24%, 
      rgba(37, 37, 37, 1) 25%, 
      rgba(50, 0, 0, 0) 52%)`,
          `background-size: 50% auto`,
          `
    @media (max-width: 760px) {
      background: linear-gradient(
        to top,
        rgba(37, 37, 37, 0) 52%, 
    rgba(244, 128, 127, 0) 68%, 
    rgba(50, 0, 0, 0) 0%),
        linear-gradient(
          to top,
          rgba(37, 37, 37, 1) 39%, 
      rgba(37, 37, 37, 1) 42%, 
      rgba(50, 0, 0, 0) 52%);
      background-size: contain;
    }
  `,
        ]}
      ></div>
      <section
        css={[
          tw`grid grid-cols-12 grid-rows-2 grid-flow-row h-auto z-10 w-full text-center mx-auto px-8 text-white`,
          tw`md:(gap-6 max-w-full px-8 grid-rows-2)`,
          tw`lg:(gap-24 max-w-desktop px-8 grid-rows-2)`,
        ]}
      >
        {headerLogo && (
          <div
            css={[
              tw`mt-0 relative block col-span-12 w-full`,
              tw`md:(col-span-6 mt-6)`,
              tw`lg:(col-span-6 mt-6)`,
            ]}
          >
            <Image
              imageData={headerLogo.image}
              //alt="XSeries Logo"
              css={[tw`w-4/5 mt-4 md:(w-auto mt-0)`]}
              transparentPreview
            />
            <h1
              css={[
                tw`block px-0 bg-white mt-8 rounded-2xl p-2 bg-opacity-80 text-center font-light text-xl italic text-black md:(px-10 text-3xl)`,
              ]}
            >
              {parseDisclaimerBlocks(headingText, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </h1>
          </div>
        )}
        <div css={[tw`col-span-12 md:(col-span-7)`]}>
          <h2
            css={[
              tw`tracking-widest font-light text-xl block mt-32 text-left`,
              tw`md:(text-2xl mt-40 mb-8)`,
              tw`lg:(text-3xl mt-40 mb-8)`,
              tw`xl:(mt-48 mb-12)`,
              tw`2xl:(mt-56 mb-24)`,
            ]}
          >
            {parseDisclaimerBlocks(subHeadingText, selection =>
              modalDispatch(toggleDisclaimersModal(selection))
            )}
          </h2>
        </div>
        <div
          css={[
            tw`col-span-12 my-4 text-left`,
            tw`md:(col-span-5 text-white flex items-center pt-16 my-0)`,
            tw`lg:(mt-24)`,
          ]}
        >
          {ctaHero && (
            <ButtonLink
              {...{ [ctaHero?.buttonType]: true }}
              to={getCTALinkValue(ctaHero)}
              target={ctaHero?.linkType === "external" ? "_blank" : "_self"}
              key={ctaHero?.title}
            >
              {ctaHero?.title}
            </ButtonLink>
          )}
        </div>
      </section>
    </section>
  )
}

export default XSeriesHeader
